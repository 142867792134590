// Generic imports
import React from "react"

// Other components imports
import { Styles } from "components/ContactUs/style"

// Redux imports

// Static imports

// Utils imports

export default function ContactUs() {
  return (
    <Styles className="layout">
      <h1>Contact Us</h1>
      <p>
        Email: tamanschool.id@gmail.com
        <br />
        Phone: +62 818 0663 4599
        <br />
        instagram: @tamanschool_
        <br />
        Twitter: @tamanschool
        <br />
      </p>
    </Styles>
  )
}
