// Generic imports
import React from "react"

// Other components imports
import ContactUs from "components/ContactUs"
import Layout from "components/layout"
import SEO from "components/seo"

// Redux imports

// Static imports

// Utils imports

const ContactUsPage = () => (
  <Layout>
    <SEO title="Contact Us" />
    <ContactUs />
  </Layout>
)

export default ContactUsPage
