// Generic imports
import styled from "styled-components"

// Static imports

export const Styles = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;

  p {
    padding-top: 10px;
    line-height: 1.5;
  }
`
